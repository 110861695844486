import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import WeddingInfoBox from './WeddingInfoBox';
import ButtonLive from './ButtonLive';
import { styWrapper } from './styles';

function WeddingSection({ isInvitation }) {
  const renderGuestInfo = () => {
    return (
      <Fragment>
        <div className="col-md-8 col-md-offset-4">
          <WeddingInfoBox title="Akad Nikah & Resepsi" time="08.00 WIB - 20.00 WIB" date="Minggu, 08 Agustus 2021" />
          {/* <WeddingInfoBox title="Resepsi" time="11.00 WIB - Selesai" date="Sabtu, 22 Mei 2021" /> */}
        </div>
        <ButtonLive />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div id="fh5co-event" css={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <span className="bismillah">Bismillahirrahmanirrahim</span>
              <h2 className="main-font main-font__wedding">Akad Nikah dan Resepsi</h2>
              <span className="sub-title sub-title__wedding">Insya Allah akan diselenggarakan pada:</span>
            </div>
          </div>
          <div className="row">
            {!isInvitation && renderGuestInfo()}
            {isInvitation && (
              <div className="col-md-10 col-md-offset-1">
                <WeddingInfoBox
                  title="Akad Nikah dan Resepsi"
                  time="08.00 WIB - 20.00 WIB"
                  date="Minggu, 08 Agustus 2021"
                  description="Kediaman Mempelai Laki-laki <br/>Kaliabang, Sukamulya, Bekasi"
                />
                {/* <WeddingInfoBox
                  title="Resepsi"
                  time="11.00 - Selesai"
                  date="Sabtu, 22 Mei 2021"
                  description="Kediaman Mempelai Laki-laki <br/>Kaliabang, Sukamulya, Bekasi"
                /> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

WeddingSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(WeddingSection);
