import React from 'react';
import IconBCA from './assets/rek-bca-obay.jpeg';
import IconMandiri from './assets/rek-mandiri-iin.jpeg';
import SectionBox from './SectionBox';

function GiftCard() {
  return (
    <div id="fh5co-couple">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2 className="main-font">Send a Gift</h2>
          </div>
        </div>
        {/* BOX INFO */}
        <div className="row">
          <div className="col-md-12">
            <SectionBox icon={IconBCA} text="BCA a.n Obay Ayudi 5775417258"/>
            <SectionBox icon={IconMandiri} text="Mandiri a.n Iin Mardianti 1320013828745"/>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftCard;
