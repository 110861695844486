import { css } from '@emotion/core';

export const styBoxWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;

  .img-section {
  }

  img {
    max-width: 200px;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .text__section {
    width: 100%;
    margin-left: 15px;
  }
  }
`;
