// import ImgKhitbah from '@assets/images/kasih-cincin.jpg';

export const stories = [
  {
    title: 'Jumpa Pertama ',
    date: '2017',
    description:
      'Allah SWT mempertemukan kami untuk pertama kalinya di kota yang istimewa, Purwakarta merupakan tempat Obay mengikuti kegiatan KKN (Kuliah Kerja Nyata) dimana disana adalah kota tempat Iin bekerja. Bermula dari saling canda. Yang menimbulkan rasa nyaman satu sama lain. Tapi waktu berjalan begitu cepat kami harus berpisah karena Obay harus kembali dan melanjutkan kesibukannya. Dan jarak pun menjadi penghalang. ',
    image: '',
  },
  {
    title: "Ta'aruf",
    date: '25 Desember 2020',
    description:
      "Terdengar suara telpon berdering. Hati berdebar melihat sosok seseorang dilayar telpon yang dulu pernah ada dan yang pernah buat nyaman satu sama lainnya. Obay mengajak Iin untuk menjalin hubungan yang lebih serius, hingga kami sepakat untuk melakukan ta'aruf, Mencari kecocokan satu sama lainnya untuk menuju ketahap selanjutnya.",
    image: '',
  },
  {
    title: 'Khitbah',
    date: '',
    description:
      'Akhirnya tiba masanya Obay dan keluarga hadir kerumah Iin untuk menyampaikan niat baiknya. Mulai dari acara Proses perkenalan kedua keluarga, Khitbah, sampai dengan proses acara pernikahan kami.',
    image: '',
  },
];
