/**
 * Link
 */
export const GOOGLE_CALENDAR_LINK = `https://calendar.google.com/calendar/event?action=TEMPLATE&dates=20210522T040000Z%2F20210522T060000Z&text=Iin+%26+Obay+Wedding&details=Iin+%26+Obay+Wedding`;
export const GOOGLE_MAPS_LINK = `https://goo.gl/maps/tkF12bq6Tx2CHjTG6`;

/**
 * Wedding time
 */
export const EPOCH_START_EVENT = 1628384400;
export const EPOCH_END_EVENT = 1628427600;
export const UTC_WEDDING_TIME = '2021-08-08:08:00:00Z';
