import React, { Fragment } from 'react';
import { GOOGLE_MAPS_LINK } from '@/constants';
import { styWrapper } from './styles';

function LocationSection() {
  return (
    <Fragment>
      <div id="fh5co-couple-story" className="fh5co-section-gray" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <h2 className="main-font">Lokasi Acara</h2>
              <p className="sub-title">
                <a
                  href={GOOGLE_MAPS_LINK}
                  title="Klik untuk melihat peta di Google Maps"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#fc03df' }}
                >
                  <button type="button" class="btn btn-primary btn-lg"><strong>Kediaman Mempelai Laki-laki</strong></button> <br /> 
                </a>{' '}
                <br />
                Kp. Kaliabang RT. 002/004 Ds. Sukamulya Kec. Sukatani, <br />
                Kab. Bekasi 17630 Jawa Barat
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.75884537309!2d107.16632075607775!3d-6.163042598279896!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69871dd57b4555%3A0x19d71778a3e0d7f1!2sJl.%20Srengseng%20Kali%20Abang%20No.129%2C%20Sukamulya%2C%20Kec.%20Sukatani%2C%20Bekasi%2C%20Jawa%20Barat%2017630!5e0!3m2!1sid!2sid!4v1627069074099!5m2!1sid!2sid"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: '0' }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
                title="Google Maps - Iin & Obay Wedding Party"
              ></iframe>
              <p className="sub-title">
                <a
                  href={GOOGLE_MAPS_LINK}
                  title="Klik untuk melihat peta di Google Maps"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#fc03df' }}
                >
                  <div class="col text-center">
                  <button type="button" class="btn btn-primary btn-lg"><strong>Buka Peta Lokasi</strong></button> <br /> 
                  </div>
                </a>{' '}
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default React.memo(LocationSection);
